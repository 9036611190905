<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcelOrders"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePromptOrders">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Filtrar por estado</label>
          <v-select :options="statesOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="stateFilter" class="mb-4 md:mb-0"/>
        </div>
      </div>
      <h5 class="mt-5 mb-2">Filtrar por fecha</h5>
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <flat-pickr :config="configdateTimePicker" v-model="startDate"
                      placeholder="Desde"
                      class="w-full"/>
        </div>
        <div class="vx-col md:w-1/2">
          <flat-pickr :config="configdateTimePicker" v-model="endDate"
                      placeholder="Hasta"
                      class="w-full"/>
        </div>
        <div class="vx-col mt-2">
          <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
        </div>
      </div>
    </vx-card>

    <detail :departments="departments" :provinces="provinces" :districts="districts" :products="productsDetail"
            :isSidebarActive="newSidebar" @closeSidebar="toggleDetail"
            :data="sidebarData"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="orders">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">
            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="orderDetail(selected)">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver detalle</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="printGo(selected)">
                            <span class="flex items-center">
                              <feather-icon icon="PrinterIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Imprimir</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="activePromptOrders=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ orders.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : orders.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="number">Id</vs-th>
        <vs-th sort-key="date">Fecha de pedido</vs-th>
        <vs-th sort-key="date">Hora de pedido</vs-th>
        <vs-th sort-key="subTotal">Subtotal</vs-th>
        <vs-th sort-key="discount">Descuento</vs-th>
        <vs-th sort-key="shipping">Costo de envío</vs-th>
        <vs-th sort-key="total">Total</vs-th>
        <vs-th sort-key="state">Forma de pago</vs-th>
        <vs-th sort-key="state">Estado</vs-th>
        <vs-th sort-key="clientName">Cliente</vs-th>
        <vs-th sort-key="clientDocumento">Distrito</vs-th>
        <vs-th sort-key="coupon">Cupón</vs-th>
        <vs-th>Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.id.substr(0, 6).toUpperCase() }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.createdAt | time(true) }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">S/ {{ (tr.total - tr.delivery + tr.discounts) | round }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">S/ {{ tr.discounts | round }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">S/ {{ tr.delivery | round }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">S/ {{ parseFloat(tr.total) | round }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ getPaymentMethodText(tr.paymentMethod) }}</p>
          </vs-td>

          <vs-td>
            <vs-chip :color="getOrderStatusColor(tr.state)" class="product-order-status">
              {{ getStateText(tr.state) | title }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.client.name + ' ' + tr.client.lastName }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">
              {{ districts.find(d => d.codDistrito === tr.address.district).nomDistrito }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.coupon.code ? tr.coupon.code : 'NN' }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon class="ml-2" icon="FileTextIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="orderDetail(tr)"/>

            <feather-icon class="ml-2" icon="PrinterIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="printGo(tr)"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Detail from './Detail'
import print from "print-js"
import vSelect from 'vue-select'

let _ = require('lodash')

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import departments from "../../data/ubigeo/departments.json"
import provinces from "../../data/ubigeo/province.json"
import districts from "../../data/ubigeo/district.json"

export default {
  components: {
    Detail,
    vSelect,
    flatPickr
  },
  data() {
    return {
      selected: null,
      itemsPerPage: 20,
      isMounted: false,
      ordersPre: [],
      products: [],
      productsDetail: [],
      initProgress: false,

      //Filters
      filter: false,

      statesOptions: [
        {label: 'Todos', value: -1},
        {label: 'Sin confirmar', value: 'hold'},
        {label: 'Aprobado', value: 'approved'},
        {label: 'Rechazado', value: 'rejected'},
        {label: 'Cancelado', value: 'cancel'},
        {label: 'Entregado', value: 'delivered'}
      ],
      deliveriesOptions: [
        {label: 'Todos', value: -1}
      ],

      stateFilter: {
        label: 'Todos',
        value: -1
      },

      startDate: '',
      endDate: '',

      //

      // Date
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },

      // Data Sidebar
      newSidebar: false,
      updateSidebar: false,
      featureSideBar: false,
      sidebarData: {},
      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      activePrompt: false,
      activePromptOrders: false,

      // Ubigeo
      departments: departments,
      provinces: provinces,
      districts: districts
    }
  },
  async created() {
    try {
      this.initProgress = true
      await this.loadOrders()
      this.initProgress = false
    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted() {
    this.isMounted = true
  },
  computed: {
    orders: {
      get() {
        return this.$store.state.orders.orders
      }
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.orders.length
    }
  },
  watch: {
    async stateFilter(val) {
      if (val.value !== -1) {
        this.initProgress = true
        this.ordersPre = []
        await this.$store.dispatch('orders/fetchOrders', {orders: this.ordersPre})

        db.collection("orders").where('state', '==', this.stateFilter.value)
            .orderBy('createdAt', 'desc').limit(100).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let order = {
              id: doc.id,
              delivery: doc.data().delivery,
              total: parseFloat(doc.data().total).toFixed(2),
              discounts: doc.data().discounts,
              state: doc.data().state,
              products: doc.data().products,
              coupon: doc.data().coupon,
              client: doc.data().client,
              address: doc.data().address,
              createdAt: doc.data().createdAt.toDate(),
              paymentMethod: doc.data().paymentMethod ? doc.data().paymentMethod : 'credit'
            }
            if (doc.data().observation) {
              order.observation = doc.data().observation
            }
            this.ordersPre.push(order)
          })
          this.initProgress = false
          this.$store.dispatch('orders/fetchOrders', {orders: this.ordersPre})
        }).catch(() => {
          this.initProgress = false
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      }
    }
  },
  methods: {
    orderDetail(data) {
      this.sidebarData = data
      this.toggleDetail(true)
    },
    toggleDetail(val = false) {
      this.newSidebar = val
    },
    getOrderStatusColor(status) {
      if (status === 'hold') return 'warning'
      if (status === 'approved') return 'dark'
      if (status === 'rejected') return 'danger'
      if (status === 'cancel') return 'danger'
      return 'success'
    },
    getStateText(value) {
      if (value === 'hold') return "Sin confirmar"
      if (value === 'approved') return "Aprobado "
      if (value === 'rejected') return "Rechazado "
      if (value === 'cancel') return "Cancelado "
      if (value === 'delivered') return "Entregado"
    },
    getPaymentMethodText(value) {
      if (value === 'credit') return "Tarjeta"
      if (value === 'plim') return "Plin"
      if (value === 'yape') return "Yape "
    },
    printGo(o) {
      const order = _.cloneDeep(o)
      let products = []
      order.products.forEach((p) => {
        let product = {
          name: p.name,
          sku: p.sku,
          price: p.priceDiscount,
          quantity: p.quantity
        }
        products.push(product)
      })

      const department = this.departments.find(d => d.codDepartamento === order.address.department).nomDepartamento
      const province = this.provinces.find(p => p.codProvincia === order.address.province).nomProvincia
      const district = this.districts.find(d => d.codDistrito === order.address.district).nomDistrito

      print({
        printable: products,
        type: 'json',
        properties: [
          {field: 'name', displayName: 'Nombre'},
          {field: 'sku', displayName: 'SKU'},
          {field: 'price', displayName: 'Precio'},
          {field: 'quantity', displayName: 'Cantidad'},
        ],
        header: `<h3 class="custom-h3">${order.id.substr(0, 6).toUpperCase()}</h3> <br> ${order.client.name} ${order.client.lastName} <br> ${order.client.phone} <br> ${order.address.address + " / " + order.address.reference + " - " + department + " - " + province + " - " + district}</h3>`,
      })
    },
    loadOrders() {
      this.ordersPre = []
      this.$store.dispatch('orders/fetchOrders', {orders: this.ordersPre})
      return new Promise((resolve, rejected) => {
        //Get orders
        db.collection("orders").orderBy('createdAt', 'desc').limit(100).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let order = {
              id: doc.id,
              delivery: doc.data().delivery,
              total: parseFloat(doc.data().total).toFixed(2),
              discounts: doc.data().discounts,
              state: doc.data().state,
              products: doc.data().products,
              coupon: doc.data().coupon,
              client: doc.data().client,
              address: doc.data().address,
              createdAt: doc.data().createdAt.toDate(),
              confirm: doc.data().confirm,
              paymentMethod: doc.data().paymentMethod ? doc.data().paymentMethod : 'credit'
            }
            if (doc.data().observation) {
              order.observation = doc.data().observation
            }
            this.ordersPre.push(order)
          })
          resolve('ok')
          this.$store.dispatch('orders/fetchOrders', {orders: this.ordersPre})
        }).catch((error) => {
          rejected(error)
        })
      })
    },
    filterData() {
      this.filter = !this.filter
    },
    async resetFilters() {
      this.initProgress = true
      await this.loadOrders()
      this.initProgress = false

      this.stateFilter = {
        label: 'Todos',
        value: -1
      }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    exportToExcelOrders() {
      try {
        this.initProgress = true
        let orders = _.cloneDeep(this.ordersPre)
        // Calculate pre oders
        let headerTitle = ['ID', 'Cliente', 'Tipo de documento', 'Documento', 'Celular', 'Departamento', 'Provincia', 'Distrito', 'Dirección', 'Estado', 'Fecha', 'Delivery', 'Total', 'Metodo de pago']
        let headerVal = ['number', 'client', 'typeDocument', 'document', 'phone', 'department', 'province', 'district', 'address', 'state', 'createdAt', 'delivery', 'total', 'paymentMethod']
        import('@/vendor/Export2Excel').then(async excel => {
          const list = []
          orders.forEach((o) => {
            const obj = {
              number: o.id.substr(0, 6).toUpperCase(),
              client: o.client.name + " " + o.client.lastName,
              typeDocument: 'DNI',
              document: o.client.document,
              phone: o.client.phone,
              paymentMethod: this.getPaymentMethodText(o.paymentMethod),
              department: this.departments.find(d => d.codDepartamento === o.address.department).nomDepartamento,
              province: this.provinces.find(p => p.codProvincia === o.address.province).nomProvincia,
              district: this.districts.find(d => d.codDistrito === o.address.district).nomDistrito,
              address: o.address.address + "/ " + o.address.reference,
              state: this.getStateText(o.state),
              createdAt: o.createdAt,
              delivery: o.delivery,
              total: o.total
            }
            list.push(obj)
          })
          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: this.fileName,
            autoWidth: this.cellAutoWidth,
            bookType: this.selectedFormat
          })
          this.clearFields()
          this.initProgress = false
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
        this.initProgress = false
      }
    },
    clearFields() {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    dateFormat(value, fullDate) {
      value = String(value)
      const date = value.slice(8, 10).trim()
      const month = value.slice(4, 7).trim()
      const year = value.slice(11, 15)

      if (!fullDate) return `${date} ${month}`
      else return `${date} ${month} ${year}`
    },
    async filterByDate() {
      try {
        this.initProgress = true
        this.ordersPre = []
        const starTimes = firebase.firestore.Timestamp.fromDate(new Date(this.startDate))
        const endTimes = firebase.firestore.Timestamp.fromDate(new Date(this.endDate))
        let querySnap = ''
        if (this.stateFilter.value === -1) {
          querySnap = await db.collection('orders')
              .where('createdAt', '>=', starTimes)
              .where('createdAt', '<=', endTimes).get()
        } else {
          querySnap = await db.collection('orders')
              .where('createdAt', '>=', starTimes)
              .where('createdAt', '<=', endTimes)
              .where('state', '==', this.stateFilter.value).get()
        }

        querySnap.forEach((doc) => {
          let order = {
            id: doc.id,
            delivery: doc.data().delivery,
            total: parseFloat(doc.data().total).toFixed(2),
            discounts: doc.data().discounts,
            state: doc.data().state,
            products: doc.data().products,
            coupon: doc.data().coupon,
            client: doc.data().client,
            address: doc.data().address,
            createdAt: doc.data().createdAt.toDate(),
            confirm: doc.data().confirm,
            paymentMethod: doc.data().paymentMethod ? doc.data().paymentMethod : 'credit'
          }
          this.ordersPre.push(order)
        })
        this.initProgress = false
        this.$store.dispatch('orders/fetchOrders', {orders: this.ordersPre})
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
